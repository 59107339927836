import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React from "react";

import { cx } from "#app/utils/cva.ts";

const Separator = React.forwardRef<
	React.ElementRef<typeof SeparatorPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, orientation = "horizontal", decorative = true, ...props }, ref) => (
	<SeparatorPrimitive.Root
		className={cx("my-2 shrink-0 bg-border", orientation === "horizontal" ? "h-px w-full" : "h-full w-px", className)}
		decorative={decorative}
		orientation={orientation}
		ref={ref}
		{...props}
	/>
));
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
